/* General */
* {
   font-family:
      DM Sans,
      sans-serif !important;
   /* Add !important to overwrite all elements */
}

.layout-row > .flex {
   flex-basis: auto;
}

.layout-column > .flex {
   flex-basis: auto;
}

.clickable {
   cursor: pointer;
}

.full-height {
   height: 100%;
}

.bordered {
   margin: 20px;
}

.dashboard-inner-container {
   padding: 24px 20px;
}

/* Rules for sizing the icon. */

.material-icons.md-18 {
   font-size: 18px;
}

.material-icons.md-24 {
   font-size: 24px;
}

.material-icons.md-36 {
   font-size: 36px;
}

.material-icons.md-48 {
   font-size: 48px;
}

.rotate {
   -ms-transform: rotate(90deg); /* IE 9 */
   -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
   transform: rotate(90deg);
}

md-toast.md-center {
   left: 50%;
   transform: translate3d(-50%, 0, 0);
}

.screen-inner-container {
   padding-top: 40px;
}

.dashboard-graphs-container {
   gap: 16px;
   padding-bottom: 40px;
}

.task-pie-card {
   margin: 0;
   border-radius: 8px;
   border: 1px solid rgba(99, 115, 129, 0.16);
}

/* Animations */

.fadein,
.fadeout {
   -webkit-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
   -moz-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
   -o-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
   transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
}

.fadein.ng-hide-remove,
.fadeout.ng-hide-add.ng-hide-add-active {
   opacity: 0;
   display: block !important;
}

.fadeout.ng-hide-add,
.fadein.ng-hide-remove.ng-hide-remove-active {
   opacity: 1;
   display: block !important;
}

/* Rules for using icons as black on a light background. */

.material-icons.md-dark {
   color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
   color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
   color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
   color: rgba(255, 255, 255, 0.3);
}

/* Toolbar */

.md-button.logo {
   height: 4em;
   width: 4em;
}

.md-button {
   border-radius: 4px;
   font-size: 13px;
   font-style: normal;
   font-weight: 700;
   line-height: 170%;
   text-transform: none;
   padding-right: 10px;
   padding-left: 10px;
}
.md-button.md-raised {
    box-shadow: none !important;
}

.md-button.md-secondary {
   border: 1px solid #000000;
}

.logo-image {
   height: 100%;
   width: 100%;
   border-radius: 50%;
}

.identity-list-image {
   height: 32px;
   width: 32px;
   border-radius: 50%;
   float: left;
   margin: 8px 10px 0 0;
}

md-toolbar {
   background-color: white !important;
   color: #637381 !important;
   border-bottom: 1px solid rgba(99, 115, 129, 0.16);
   padding: 0 20px !important;
}

md-toolbar.fixed {
   position: fixed;
}

.md-toolbar-tools h3 {
   color: #212b36;
   font-size: 20px;
   font-style: normal;
   font-weight: 700;
   line-height: 150%;
}

.fixed-toolbar-friendly {
   margin-top: 60px;
}

md-toolbar h2 {
   display: inline-block;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}

.nowrap {
   white-space: nowrap;
}

.searchinline {
   outline: none;
   border: none;
   border-bottom: 1px dotted;
}

/* Sidenav */
md-sidenav {
   width: 248px !important;
   padding-top: 24px;
   padding-bottom: 16px;
   border-right: 1px solid rgba(99, 115, 129, 0.16);
}

.md-list-item-sidenav {
   border-radius: 8px;
   padding: 0 !important;
}

.md-list-item-sidenav:hover .sidenav-icon{
   color: #00AB55 !important;
}

md-list-item.md-clickable:hover {
   background-color: rgba(0, 171, 85, 0.12) !important;
}

md-sidenav a {
   text-decoration: none;
   font-size: 15px;
}

.top-layer {
    z-index: 100;
}

/* Section */

.section-header {
   font-size: 1.2em;
   cursor: pointer;
   border-radius: 8px;
   padding-left: 24px !important;
   padding-right: 12px !important;
   padding-top: 8px;
   padding-bottom: 8px;
}

.section-header:focus {
   outline: 0;
}

.section-header .md-subheader-inner {
   width: 100%;
}

.section-header-rename .md-subheader-inner {
   width: 100%;
   padding: 5px;
}

.section-header-menu {
   position: absolute;
   right: -10px;
   top: -10px;
}

.section-header-menu-rename {
   position: absolute;
   right: 0;
   top: 0;
}

/* Search */

.search-instances-form {
   position: relative;
   border-radius: 8px;
   border: 1px solid #6373813b;
   background: #ffffff;
}

.search-instances-form .search-button {
   position: absolute;
   left: 10px;
   top: 6px;
}

.search-instances-form .clear-button {
   position: absolute;
   right: 10px;
   top: 6px;
}

.search-instances-box {
   background-color: rgba(255, 255, 255, 0.06);
   border-radius: 4px;
   border: solid 1px rgba(255, 255, 255, 0.15);
   font-size: initial;
   height: 36px;
   min-width: 100px;
   padding-left: 50px;
   width: 100%;
}

md-card-header > ng-md-icon {
   margin: 0;
}

.search-tools {
   margin-left: 16px;
   margin-top: 12px;
}

.search-tools ng-md-icon {
   fill: gray;
}

.no-results {
   margin: 50px auto 0 auto;
   padding: 50px;
   width: 300px;
   height: 300px;
   background: url(images/no-results.gif) top no-repeat;
}

.no-results > div {
   margin-top: 200px;
   text-align: center;
   font-size: 0.9em;
   color: rgba(0, 0, 0, 0.6);
}

.job-done {
   margin: 100px auto 0 auto;
   padding: 50px;
   width: 200px;
   height: 200px;
   background: url(images/job-done.png) top no-repeat;
}

.job-done > div {
   margin-top: 150px;
   text-align: center;
   font-size: 0.9em;
   color: rgba(0, 0, 0, 0.6);
}

.paperwork {
   margin: 100px auto 0 auto;
   padding: 50px;
   width: 200px;
   height: 200px;
   background: url(images/paperwork.png) top no-repeat;
}

.paperwork > div {
   margin-top: 150px;
   text-align: center;
   font-size: 0.9em;
   color: rgba(0, 0, 0, 0.6);
}

div[role='button']:focus {
   outline: 0;
}

/* Positioning */

.bottom-right-FAB {
   position: fixed;
   right: 10px;
   bottom: 10px;
   z-index: 1;
   align-items: end;
}

.action-icon {
   margin: auto 16px auto 0;
}

.comment-input-container {
   margin-top: 8px;
}

.overlay-icon-br {
   position: absolute;
   left: 32px;
   top: 32px;
   color: rgba(0, 0, 0, 0.6);
}

.md-2-line-icon {
   line-height: normal;
}

/* Font sizes */

.detail {
   font-size: 0.8em;
}

section .page {
   background: linear-gradient(to left top, #70694f, #f5eee4) fixed;
}

section header .title {
   margin-top: 50px;
   margin-bottom: 50px;
   text-align: center;
   font-size: 2em;
   font-weight: 300;
   color: rgba(0, 0, 0, 0.4);
}

section .text {
   position: absolute;
   left: 20px;
   bottom: 20px;
   color: white;
   width: 50%;
   font-size: 1.2em;
   font-weight: 300;
}

section .page .dark {
   color: rgba(0, 0, 0, 0.6);
}

section .page .white {
   color: white;
}

.how-it-goes-chart {
   margin-top: 20px;
   position: relative;
   color: white;
}

.how-it-goes-chart img {
   width: 200px;
}

.how-it-goes-chart .legend {
   position: absolute;
   top: 30px;
   width: 200px;
}

.how-it-goes-chart .legend .title {
   text-align: center;
   font-size: 16px;
   line-height: 24px;
   color: rgba(0, 0, 0, 0.4);
}

.how-it-goes-chart .legend .number {
   font-size: 45px;
   line-height: 20px;
   width: 200px;
   text-align: center;
}

.header {
   padding: 5px;
}

.ngCytoscape {
   height: 500px;
   width: 100%;
}

/* Profile */

.profile-image {
   border-radius: 50%;
   max-width: 100px;
   margin: auto 20px auto auto;
}

md-input-container > ng-md-icon {
   position: absolute;
   top: 10px;
   left: 93%;
}

md-input-container > ng-md-icon + input {
   margin-left: 56px;
}

/* Customize */

md-dialog-content md-input-container {
   margin-top: 10px;
   margin-bottom: 0;
}

md-dialog-content md-autocomplete > md-autocomplete-wrap.md-whiteframe-z1 {
   box-shadow: none;
}

md-dialog-content div.layout-margin > * {
   margin: 0;
}

.widget-customize-dialog md-list[dnd-list] md-list-item.selected {
   background-color: rgb(0 0 0 / 15%);
}

.widget-customize-dialog md-list[dnd-list] .dndPlaceholder {
   background-color: #aaaaaa;
   display: block;
   min-height: 72px;
}

.widget-customize-dialog md-list[dnd-list] .dndDraggingSource {
   display: none;
}

.widget-customize-dialog-subheader {
   margin-left: 16px !important;
   margin-top: 8px !important;
   margin-bottom: 8px !important;
}

/*Rules for displaying theme in project*/
.theme-line {
   padding: 16px;
   width: 150px;
}

.theme-primary,
.theme-accent {
   margin: 4px 0;
   height: 60px;
}

/*Instance list*/
.instance-list-table-header {
   background-color: rgba(51, 102, 255, 0.12);
}

.instance-list-table-header-text {
   color: #212b36 !important;
}

.instance-list-link {
   text-decoration: none;
   font-weight: 500;
}

.instance-list-link:hover {
   text-decoration: underline;
}

.instance-list-active {
   color: #4588e6;
}

.instance-list-task-item {
   padding: 12px;
}

.instance-list-cell-width-15 {
   width: 15%;
   color: #212b36 !important;
   font-weight: 500;
}

.instance-list-cell-width-20 {
   width: 20%;
   color: #212b36 !important;
   font-weight: 500;
}

.instance-list-cell-width-45 {
   width: 45%;
   color: #212b36 !important;
   font-weight: 500;
}

.md-toggle-icon {
   transform: rotate(180deg);
   transition: transform 0.3s ease-in-out;
}

.md-toggle-icon.toggled {
   transform: rotate(0deg);
}

/*New Instance page*/
.screen-content {
   padding: 24px 20px;
}

/*Queues*/

.queues-table-content-head {
   background-color: rgba(145, 158, 171, 0.08);
   border-radius: 8px;
}

/*inputs*/
md-input-container .md-input {
   border-radius: 8px !important;
   border-width: 1px !important;
   border-style: solid !important;
   padding: 8px 28px 8px 14px !important;
   height: 40px;
   background-color: #ffffff !important;
   line-height: 22px;
}

md-select {
   height: 40px;
   margin: 0;
}

md-select.md-default-theme .md-select-value,
md-select .md-select-value {
   border-radius: 8px !important;
   padding: 5px 8px 5px 12px !important;
   border: 1px solid #6373813b;
}

md-select.md-default-theme:not([disabled]):focus .md-select-value,
md-select:not([disabled]):focus .md-select-value {
   border-color: rgb(51, 102, 255);
}

md-select.md-default-theme[disabled] .md-select-value, md-select[disabled] .md-select-value, md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder, md-select[disabled] .md-select-value.md-select-placeholder {
   border-bottom-color: rgba(0,0,0,0.12);
   border-style: dotted !important;
   background-image: none !important;
   color: rgba(0,0,0,0.38);
}

md-select.md-default-theme.ng-invalid.ng-touched .md-select-value, md-select.ng-invalid.ng-touched .md-select-value {
   color: rgb(213,0,0)!important;
   border-color: rgb(213,0,0)!important;
}

md-input-container label:not(.md-container-ignore) {
   position: absolute;
   bottom: 94%;
   left: 14px;
   right: auto;
   width: auto !important;
   background-color: white;
   padding-left: 4px !important;
   padding-right: 4px !important;
}

md-input-container.md-input-focused label:not(.md-no-float),
md-input-container.md-input-has-placeholder label:not(.md-no-float),
md-input-container.md-input-has-value label:not(.md-no-float) {
   transform: translate3d(0, 12px, 0) scale(0.75) !important;
}
md-input-container {
   margin: 0;
}

[disabled] md-input-container.md-default-theme .md-input,
[disabled] md-input-container .md-input,
md-input-container.md-default-theme .md-input[disabled],
md-input-container .md-input[disabled] {
   border-bottom-color: revert;
   border-style: dotted !important;
   color: rgba(0, 0, 0, 0.38);
   background-image: none !important;
}

/*Switches*/
md-switch .md-thumb {
   background-color: #81c926;
}

md-switch .md-bar {
   background-color: rgba(44, 64, 90, 0.2);
}

md-switch.md-checked .md-bar {
   background-color: rgba(44, 64, 90, 0.2);
}

/* Diagram */

text.fa {
   font-family: FontAwesome, sans-serif;
}

.hide-validation-error .md-errors-spacer {
   display: none;
}

div.property-sheet {
   background-color: white;
   padding: 10px;
   min-width: 400px !important;
}

div.design-nav {
   background-color: white;
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.diagram {
   min-width: 300px !important;
}

.diagram div.rg-right {
   margin-right: 0;
}

.simple-border {
   border-radius: 2px;
   box-shadow: 0 1px 2px 0 rgba(0, 121, 196, 0.09);
   border: solid 0.5px rgba(183, 210, 229, 0.8);
}

.error-border {
   border-radius: 2px;
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
   border: solid 0.5px rgba(213, 0, 0, 0.8);
}

.error-small-text {
   color: rgba(213, 0, 0, 1);
   font-size: 12px;
   padding-top: 5px !important;
}

.no-resize {
   resize: none !important;
   max-height: 300px !important;
   overflow-y: scroll !important;
}

.no-padding {
   padding: 0 !important;
}

.sidebar-width {
   max-width: 400px !important;
}

.no-min-height {
   min-height: auto !important;
   height: 25px !important;
}

.big-icon {
   width: auto !important;
   min-height: 64px !important;
}

.object-details {
   color: rgba(0, 0, 0, 0.54);
   font-size: 14px;
}

.tooltip-multiline {
   height: auto !important;
   text-align: center;
   max-width: 300px;
   white-space: normal;
   font-size: 14px;
}

.sidebar-list {
   display: initial !important;
}

.inherit-height {
   height: inherit !important;
}

.widget-left-margin {
   margin-left: 24px;
   margin-right: 24px;
}

.widgetlabel-color {
   font-size: 16px;
   color: #637381;
}

.widgetlabel-icon {
   margin: auto 4px;
}

.checkbox-margin {
   margin-bottom: 0;
   color: darkgrey;
}

.radio-margin {
   margin-bottom: 4px;
   color: black;
   display: inline;
   align-self: flex-start;
}

md-radio-button.md-default-theme .md-off,
md-radio-button .md-off {
   border-color: black;
}

md-radio-button[disabled] .md-container .md-off {
   border-color: gray;
}

.md-button.md-fab[disabled] {
   background-color: rgb(215 215 215);
}

md-card.plain {
   margin: 0;
   box-shadow: none;
}

.angular-google-map-container {
   height: 400px;
}

button.button-checkbox {
   margin: 0;
   text-align: left;
}

.min300 {
   min-width: 300px;
}

.no-margin {
   margin: 0 !important;
}

.text-right {
   text-align: right !important;
}

#svg {
   width: 1000px;
   height: 500px;
   display: block;
}

.widget-container {
   min-height: 16px !important;
}

#map {
   height: 400px;
}

.task-table-filter-icons {
   padding-bottom: 24px;
   padding-left: 16px;
}

.task-card {
   border-radius: 16px;
   padding: 24px 24px 16px 24px;
}

.task-card-selected {
   background-color: rgba(0, 121, 196, 0.6);
}

.task-card-owner {
   background-color: #bbdefb;
}

.task-card-no-owner {
   background-color: #c8e6c9;
}

.task-card-suspended {
   background-color: #ffb3a7;
}

.task-card-header {
   padding: 0;
}

.task-card-content {
   padding: 16px 0px 12px 0;
}

.md-title {
   font-size: 14px !important;
   font-style: normal;
   font-weight: 700 !important;
   line-height: 160%;
   letter-spacing: 0.1px;
}

.task-card-actions {
   margin: 0;
}

.no-process {
   margin: 16px;
}

.no-transition {
   transition: none !important;
}

md-checkbox .md-label {
   color: black;
}

/* angular-material-datetimepicker styles */

.dtp table.dtp-picker-days tr > td > a.selected,
.dtp table.dtp-picker-days tr > td > a.selected.hilite,
.dtp div.dtp-date,
.dtp div.dtp-time,
.dtp .dtp-hand.on,
.dtp .dtp-actual-meridien a.selected,
.dtp .dtp-picker-time > a.dtp-select-hour.selected {
   background: rgb(0, 147, 238);
}

.dtp table.dtp-picker-days tr > td > a.hilite:not(.selected),
.dtp div.dtp-actual-time.p60 span.selected {
   color: rgb(0, 147, 238);
}

.dtp div.dtp-year-btn,
.dtp div.dtp-actual-year,
.dtp div.dtp-actual-maxtime {
   color: #d0f0f0;
}

.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
   background: rgb(187, 222, 251);
}

md-menu-content.dtp-month-list {
   background-color: #d0f0f0;
}

md-menu-content.dtp-year-list {
   background-color: #d0f0f0;
}

md-autocomplete {
   background-color: transparent;
}

.section-container {
   border-radius: 8px;
   border: 1px solid rgba(99, 115, 129, 0.16);
}

.section-content {
   padding: 16px 24px;
}

.task-design-widget-button {
   border: 1px solid black;
   margin: 0;
}

.task-design-widgets-container {
   display: flex;
   flex-wrap: wrap;
   gap: 8px;
}

.task-design-container {
   display: flex;
   row-gap: 16px;
}

.task-design-list-icon {
   margin-right: 16px !important;
   margin-left: 16px;
   padding: 0;
}

.md-subheader {
   font-size: 18px;
   font-weight: 700;
}

md-list-item._md-button-wrap > div.md-button:first-child {
   padding: 0;
}

.md-subheader .md-subheader-inner {
   display: block;
   padding: 5px 0;
}

.md-resize-wrapper {
   margin-top: 12px;
   position: relative;
}

.user-card {
   border-radius: 16px;
   padding: 24px 24px 16px 24px;
}

.user-card-content {
   padding: 0px 0px 16px 0;
}

.saga-logo-sidenav {
   width: 120px;
   margin: 5px 50px 40px 62px;
}

.sidenav-list {
   padding: 12px 16px;
}

.sidenav-icon {
   line-height: 24px;
   margin-right: 32px;
   margin-left: 16px;
}

.sidenav-icon-color {
   color: #637381;
}

.add-document-dialog-button {
   margin-top: 8px;
}

md-list-item .md-secondary-container .md-button {
   border: none;
}

md-list-item {
   /*height: 44px !important;*/
}

.invalid-link {
   padding-top: 42px;
   width: 50%;
}

.new-timesheet {
   margin-right: 24px;
   margin-left: 24px;
   margin-top: 24px;
}

.timesheet-error-container {
   padding-left: 28px;
   padding-bottom: 12px;
}

.timesheet-error {
   color: #FF0000FF;
   font-size: 14px;
}

.select-header-container .header-searchbox {
   border: none;
   outline: none;
   height: 100%;
   width: 100%;
   padding: 0;
}
.select-header-container .select-header {
   box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
   padding-left: 16px;
   height: 48px;
   cursor: pointer;
   position: relative;
   display: flex;
   width: auto;
}
.select-header-container md-content._md {
   max-height: 240px;
}
.select-header-container md-input-container {
   min-width: 112px;
}

.select-clear-button {
   position: absolute;
   height: 100%;
   display: flex;
   align-items: center;
   cursor: pointer;
   right: 18px;
   min-width: 24px;
   padding: 0;
   top: -6px;
   color: rgba(0, 0, 0, 0.54);
}

.select-container {
   position: relative;
}

.select-all-button {
   width: 100%;
   text-align: left;
}
.select-input-container {
   height: 64px;
}

.new-timesheet md-input-container .md-errors-spacer {
   float: right;
   min-height: 6px;
   min-width: 1px;
}

.textarea-label {
   top: -4px;
   height: 16px;
}

md-input-container textarea.md-input {
   min-height: 22px;
}

.task-design-sidenav {
   padding-right: 12px;
   padding-left: 12px;
}

.outlined {
   border: 1px solid black;
}
